
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import JwtDecode from "jwt-decode";
    import RoutesEnum from "@/router/routesEnum";
    import RegisterCustomerForm from "@/app/authentication/views/RegisterCustomerForm.vue";
    import RegisterCustomerFormSucess from "@/app/authentication/views/RegisterCustomerFormSucess.vue";

    @Component
    export default class RegisterCustomer extends Vue
    {
        private token: string = "";
        private name: string = "";
        private email: string = "";
        private password: string = "";
        private confirmPassword: string = "";
        sectionDisplayed: number = 0;

        get components()
        {
            let components = {
                "register-customer-form": RegisterCustomerForm,
                "register-customer-form-sucess": RegisterCustomerFormSucess
            };

            return components;
        }

        mounted()
        {
            this.token = String(this.$route.query?.token);
            let tokenDecoded: any = JwtDecode(this.token);
            this.name = tokenDecoded.customerName;
        }

        onClickHome()
		{
			this.$router.push({ name: RoutesEnum.Package });
		}
    }
