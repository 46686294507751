
    import { Component, Prop, Vue } from "vue-property-decorator";
    import JwtDecode from "jwt-decode";
    import RoutesEnum from "@/router/routesEnum";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";

    @Component
    export default class RegisterCustomerFormSucess extends Vue
    {
        private token: string = "";
        private name: string = "";
        private email: string = "";
        private password: string = "";
		private confirmPassword: string = "";

        mounted()
        {
            this.token = String(this.$route.query?.token);
            let tokenDecoded: any = JwtDecode(this.token);
            this.name = tokenDecoded.customerName;
        }

        onClickHome()
		{
			this.$router.push({ name: RoutesEnum.Package });
        }

        onclickOpenConnectionBox()
        {
            this.$store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
        }
    }
