
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import JwtDecode from "jwt-decode";
    import RoutesEnum from "@/router/routesEnum";
    import { AxiosError } from "axios";
    import { LoginRegisterCustomerBody } from "@/apiManager/login/loginData";
    import { ErrorServerCodeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class RegisterCustomerForm extends Vue
    {
        private token: string = "";
        private name: string = "";
        private email: string = "";
        private password: string = "";
        private confirmPassword: string = "";
        private registerInProgress: boolean = false;

        private hasErrorServer: boolean = false;
        private errorServerMessage: string = "Malheureusement, nous avons eu une erreur de communication. Veuillez réessayer.";

        @PropSync("sectionDisplayed", { type: Number })
		private syncedSectionDisplayed!: number;

        mounted()
        {
            this.token = String(this.$route.query?.token);
            let tokenDecoded: any = JwtDecode(this.token);
            this.name = tokenDecoded.customerName;
        }

        onClickHome()
		{
			this.$router.push({ name: RoutesEnum.Package });
        }

        onClickSubscriptionSend()
		{
			this.syncedSectionDisplayed = 1;
		}

        async onClickSubmit()
        {
            this.hasErrorServer = false;
            if (
                this.errorEmail === true ||
                this.errorPassword === true ||
                this.errorConfirmPassword === true ||
                this.email.trim().length === 0 ||
                this.password.trim().length === 0 ||
                this.confirmPassword.trim().length === 0
                )
            {
                return;
            }

            try
            {
                this.registerInProgress = true;

                let body: LoginRegisterCustomerBody = new LoginRegisterCustomerBody(this.email, this.password, this.token);
                await this.$apiManager.login.registerCustomerAsync(body);

                this.onClickSubscriptionSend();
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
                {
                    case ErrorServerCodeEnum.CustomerTokenInvalid:
                    case ErrorServerCodeEnum.CustomerTokenExpired:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorCustomerTokenInvalid);
                        break;
                    case ErrorServerCodeEnum.UserCreateEmailAlreadyUsed:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorAuthEmailAlreayUsed);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorAuthCreate);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
                        break;
                    default:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.hasErrorServer = true;
            }
            finally
            {
                this.registerInProgress = false;
            }
        }

        get errorEmail()
		{
			if (this.email.length > 0)
			{
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorPassword()
		{
			if (this.password.length > 0)
			{
				if (this.password.length < 17)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorConfirmPassword()
		{
			if (this.confirmPassword.length > 0)
			{
				if (this.confirmPassword === this.password)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}
    }
